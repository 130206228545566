import {Box, Button, Flash, IconButton, Text, Link} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {Dialog} from '@primer/react/experimental'
import {useRef, useState} from 'react'
import {verifiedFetch} from '@github-ui/verified-fetch'
import {ArrowLeftIcon, ArrowRightIcon, PackageIcon, TagIcon} from '@primer/octicons-react'

interface ImmutableActionsMigrationDialogProps {
  repoName: string
  releaseTags: string[]
  nonSemverTags: string[]
  migratePath: string
  setMigrationDialogOpen: (open: boolean) => void
  setHasUnmigratableTagsDialogOpen: (open: boolean) => void
  setMigrationStatus: (status: string | null) => void
}

export function ImmutableActionsMigrationDialog({
  repoName,
  releaseTags,
  nonSemverTags,
  migratePath,
  setMigrationDialogOpen,
  setHasUnmigratableTagsDialogOpen,
  setMigrationStatus,
}: ImmutableActionsMigrationDialogProps) {
  const returnFocusRef = useRef(null)
  const numOfSemVerTags = releaseTags.length - nonSemverTags.length
  const [error, setError] = useState<string | null>(null)

  async function handleSubmit() {
    const formData = new FormData()

    const result = await verifiedFetch(migratePath, {
      method: 'POST',
      body: formData,
    })

    if (result.ok) {
      setMigrationStatus('in_progress')
      setMigrationDialogOpen(false)
    } else {
      setError('Failed to start migration, please try again')
    }
  }

  return (
    <Dialog
      returnFocusRef={returnFocusRef}
      onClose={() => {
        setMigrationDialogOpen(false)
        setError(null)
      }}
      width="large"
      aria-labelledby="header"
      title={
        <div className="d-flex">
          {nonSemverTags.length > 0 && (
            <IconButton
              icon={ArrowLeftIcon}
              size="small"
              className="mr-2"
              aria-labelledby="back"
              variant="invisible"
              data-testid="ia-migration-back"
              onClick={() => {
                setMigrationDialogOpen(false)
                setHasUnmigratableTagsDialogOpen(true)
                setError(null)
              }}
              sx={{':hover': {color: 'fg.muted'}}}
            />
          )}
          <Text data-testid="ia-migration-title" sx={{marginTop: 'auto', marginBottom: 'auto'}}>
            Create immutable action
          </Text>
        </div>
      }
      renderFooter={() => {
        return (
          <Dialog.Footer>
            <Link sx={{marginRight: 'auto', pt: 1}} href="https://docs.github.com/actions">
              Learn more
            </Link>
            <Button type="button" onClick={() => setMigrationDialogOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" onClick={handleSubmit} data-testid="ia-migration-button-dialog">
              Create immutable action
            </Button>
          </Dialog.Footer>
        )
      }}
    >
      <div data-testid="inner">
        {error && (
          <Flash variant="danger" className="mb-3">
            {error}
          </Flash>
        )}
        <div className="d-flex my-2" style={{margin: 'auto', justifyContent: 'center'}}>
          <Box
            sx={{
              height: 52,
              borderRadius: 26,
              border: '1px solid',
              alignItems: 'center',
              display: 'flex',
              marginBottom: 2,
              paddingLeft: '16px',
              paddingRight: '16px',
              boxShadow: 'shadow.medium',
            }}
          >
            <Octicon icon={TagIcon} size={16} className="mr-1" sx={{margin: 'auto', color: 'fg.muted'}} />
            {pluralize('tag', numOfSemVerTags)}
          </Box>
          <Octicon icon={ArrowRightIcon} size={16} className="mx-2" sx={{marginTop: 'auto', marginBottom: 'auto'}} />
          <Box
            sx={{
              height: 52,
              borderRadius: 26,
              border: '1px solid',
              alignItems: 'center',
              display: 'flex',
              marginBottom: 2,
              paddingLeft: '16px',
              paddingRight: '16px',
              boxShadow: 'shadow.medium',
            }}
          >
            <Octicon icon={PackageIcon} size={16} className="mr-1" sx={{margin: 'auto', color: 'fg.muted'}} />
            {pluralize('version', numOfSemVerTags)}
          </Box>
        </div>
        <div>
          <p>
            <strong>
              {numOfSemVerTags} out of {releaseTags.length} tags
            </strong>{' '}
            from <strong>{repoName}</strong> will be recreated as immutable package versions.
          </p>
          <p>
            After this process you can activate your package, at which point references to semantic versions of your
            action will be resolved from immutable package versions rather than git references. Non-semantic version
            references to your action will continue to be resolved from git references.
          </p>
        </div>
      </div>
    </Dialog>
  )
}

function pluralize(word: string, count: number) {
  return `${count} ${word}${count > 1 ? 's' : ''}`
}

try{ ImmutableActionsMigrationDialog.displayName ||= 'ImmutableActionsMigrationDialog' } catch {}